import React from "react";
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appleAppStoreLink,
  appliedConfig,
  googlePlayStoreLink,
} from "../config/commonConfig";
const AboutUs = () => {
  return (
    <section
      id="about"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
          appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-5 "
            : " ")
        }
      >
        <div className="row">
          <div className="col-lg-6 text-center">
            {" "}
            <img className="img-fluid" src="images/about-app.png" alt="" />{" "}
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <p>
            En Buenaventa, creemos que todos los pequeños negocios merecen acceso a herramientas tecnológicas que les permitan optimizar sus operaciones, mejorar su eficiencia y alcanzar sus metas. Nuestra misión es facilitar la digitalización de estos negocios, ayudándolos a competir en un mercado en constante evolución y asegurando su éxito a largo plazo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
