import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import {
  HeaderConfig,
  appliedConfig,
} from "../config/commonConfig";

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setSendingMail(true);
  
    const formData = new FormData(form.current);
    const emailPayload = {
      NombreFrom: formData.get("user_name"),
      Subject: "Mensaje de contacto",
      Body: `
        <h2>Mensaje de Contacto</h2>
        <p><strong>Nombre:</strong> ${formData.get("user_name")}</p>
        <p><strong>Email:</strong> ${formData.get("user_email")}</p>
        <p><strong>Mensaje:</strong></p>
        <p>${formData.get("message")}</p>
      `,
      Attachments: [],
    };
  
    try {
      const response = await fetch("https://apinotification.metraxapp.cl/api/email/enviarweb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });
  
      if (response.ok) {
        document.getElementById("contact-form").reset();
        toast.success("¡Mensaje enviado exitosamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const errorText = await response.text();
        toast.error(`Error al enviar el mensaje: ${errorText}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Error en la solicitud. Verifica la configuración.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error en la solicitud:", error);
    } finally {
      setSendingMail(false);
    }
  };

  return (
    <section
      id="contact"
      className={
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "bg-light"
          : ""
      }
    >
      <div className="row g-0">
        <div className="col-md-6 col-lg-7">
          <div className="section px-4 px-sm-5 mx-lg-5">
            <h2 className="fw-600 text-center mb-3">Contacto</h2>
            <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
            <form id="contact-form" ref={form} onSubmit={sendEmail}>
              <div className="mb-3">
                <input
                  id="name"
                  name="user_name"
                  type="text"
                  className="form-control border-2"
                  required
                  placeholder="Nombre"
                />
              </div>
              <div className="mb-3">
                <input
                  id="email"
                  name="user_email"
                  type="email"
                  className="form-control border-2"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <textarea
                  id="form-message"
                  name="message"
                  className="form-control border-2"
                  rows={3}
                  required
                  placeholder="Mensaje........"
                  defaultValue={""}
                />
              </div>
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary text-uppercase"
                  type="submit"
                  disabled={sendingMail}
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Enviando.....
                    </>
                  ) : (
                    <>{"Enviar Mensaje"}</>
                  )}
                </button>
              </p>
            </form>
          </div>
        </div>
        <div
          className={
            "col-md-6 col-lg-5  " +
            (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
            appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
              ? "bg-white"
              : "bg-light")
          }
        >
          <section className="section px-4 px-sm-5 mx-lg-4">
            <h2 className="fw-600 mb-3">Oficina Central</h2>
            <hr className="heading-separator-line bg-primary opacity-10" />
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                <i className="fas fa-map-marker-alt" />
              </div>
              <h3>Metrax IT.</h3>
              <p>
                Av. Los Pajaritos 3195, oficina 420, Maipú, Región Metropolitana
              </p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                <i className="fas fa-phone" />
              </div>
              <h3>Teléfono</h3>
              <p> WhatsApp (+569) 935195587</p>
              <p> LLamada (+569) 930661032</p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                <i className="fas fa-envelope" />
              </div>
              <h3>Correo contacto</h3>
              <p>Jonathan.arce@buenaventa.cl</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Contact;
