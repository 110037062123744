import React from "react";
import { Parallax } from "react-parallax";

const divStyleBg = {
  backgroundColor: "rgba(0, 0, 50, 1)", // Color con opacidad al 100%
};


function Counter() {
  return (
    <section id="counter" className=" hero-wrap">
      <div className="hero-mask opacity-8 bg-dark" />

      <Parallax
      style={divStyleBg}
        bgImage=""
        bgImageAlt="Intro"
        strength={100}
      >
        <div className="hero-content section">
          <div className="container">
            
          </div>
        </div>
      </Parallax>
    </section>
  );
}

export default Counter;
